<script setup>
        /**Pls do not hard delete it*/
    import { defineProps,ref} from "vue";
    import {createDataObjectFromArray} from "o365.modules.VueDataObject.js";
    import {OFilter} from 'o365.vue.components.Filter.js';
    import OInputEditors from 'o365.lookup.InputEditors.vue';
    components:{OFilter,OInputEditors};
    const props = defineProps(['filterObject','istemplate']);
    
    const id = "_accordion_" + Math.floor(Math.random() * 100);



    const dsFields = createDataObjectFromArray({
        id: "o_dsFieldList_" + props.filterObject.dataObject.viewName,
        data: props.filterObject.columns,
        fields: [{ name: "name"}, 
            {name: "caption"}, 
            {name: "type"}]
    });


    
    const compareSelect = ['Input editor','Field','Fixed value'];

    const fieldSelect = (sel,item)=>{
        item.column=sel.name;
    }

    const fieldValueSelect = (sel,item)=>{
        item.displayValue = sel.caption;
        item.value = sel.name;
    }


    const setInputEditorFilter = (pType)=>{
        let vInputEditorFilter = {};
        switch(pType){
             case "date":
                vInputEditorFilter = {
                    column:"Name",
                    operator:"inlist",
                    value:['Date_Picker','TextBox']
                }
                break;
            case "datetime":
            case "time":
                vInputEditorFilter = {
                    column:"Name",
                    operator:"inlist",
                    value:['Date_Picker','TextBox','TimePicker']
                }
                break;
            case "bit":
            case "boolean":
                vInputEditorFilter = {
                    column:"Name",
                    operator:"inlist",
                    value:['Checkbox','TextBox']
                }
                break;
            case "number":
                vInputEditorFilter = {
                    column:"Name",
                    operator:"notinlist",
                    value:['Checkbox','TimePicker', 'Date_Picker']
                }
                break;
            default:
                vInputEditorFilter = {
                    column:"Name",
                    operator:"notinlist",
                    value:['Checkbox','TimePicker', 'Date_Picker']
                }
            
        }

        o365.model.dataObjects['o_dsInputEditors'].filter.clearFilter();
        o365.model.dataObjects['o_dsInputEditors'].filter.addItem(vInputEditorFilter);
        o365.model.dataObjects['o_dsInputEditors'].load();
    }

    const inputEditorSelect = (sel,item) =>{

        item.displayValue = sel.Name;
        item.value = "{{"+sel.Name+"}}";
    }

    const addItem = (event) =>{
       
        props.filterObject.addEmptyItem({});
        if(event) event.stopPropagation();
    }

   

    
 
    const saveFilter = () =>{
        let modal = bootstrap.Modal.getOrCreateInstance(filterbuilder.value);
        modal.hide();

      // props.filterObject.updateFilterItems();
    }

    const addToFilter = () =>{

       // const vString = item.value.getString();
       // console.log(vString);
       // props.filterObject.removeItem(item.value);
        addItem();
        item.value = props.filterObject.emptyItem;
        
    }

      
    const item = ref(props.filterObject.emptyItem);

    
</script>
<template>
    <div class="row mt-1">

        <label class="col-3 pe-0">
            {{$t('Column')}}
        </label>
        <label class="col-2 pe-0 ps-1">
            {{$t('Operator')}}
        </label>
        <label class="col-2 pe-0 ps-1">
            {{$t('Compare against')}}
        </label>
        <label class="col-4 pe-0 ps-1">
            {{$t('Value')}}
        </label>
       


    </div>

    <div class="row mt-1">

        <label class="col-3 pe-0">
            <o-data-lookup :data-object="dsFields" 
                v-model="item.displayColumn"
                :bind="sel=> fieldSelect(sel,item)"
                class="form-select form-select-sm rounded-0"
                 :placeHolder="$t('Choose column...')"
                >
               
                <o-column field="caption" width="300"></o-column>
            </o-data-lookup>
        </label>
        <label class="col-2 pe-0 ps-1 text-truncate">
            <select class="form-select form-select-sm rounded-0"  :value="item.operatorTitle" :disabled="!item.column" >
            <option @click="item.operator=op.name" v-for="op in item.operators" >
                {{op.title}}
            </option>
        </select>
        </label>
        <label class="col-2 pe-0 ps-1">
                <select class="form-select form-select-sm rounded-0"  v-model="item.compareAgainst"  :disabled="!item.operator">
                <option v-for="comp in compareSelect">
                    {{$t(comp)}}
                </option>
            </select>
        </label>
        <label class="col-3 pe-0 position-relative filter-template ps-1">
                <o-data-lookup 
                v-if="item.compareAgainst && item.compareAgainst == 'Field'"
                v-model="item.displayValue"
                :data-object="dsFields"
                :placeHolder="$t('Choose column')"
                class="form-select form-select-sm rounded-0"
                :bind="sel=>fieldValueSelect(sel,item)">
                <o-column field="caption" width="300"></o-column>
            </o-data-lookup>

            <o-filter :inputClassName="'form-control form-control-sm rounded-0 position-relative'" :column="item.columnProps" :item="item" v-if="item.operator && item.compareAgainst == 'Fixed value'"></o-filter>
        
            <OInputEditors
                v-model="item.displayValue"
                @onbeforeopen ="setInputEditorFilter(item.valueType)"
                v-if="item.compareAgainst && item.compareAgainst == 'Input editor'"
                :placeHolder="$t('Choose editor...')"
                :bind="sel=>inputEditorSelect(sel,item)"
                class="form-select form-select-sm rounded-0"
            ></OInputEditors>
            <input class="form-control form-control-sm rounded-0" disabled v-if="!item.column">
        </label>
        <div class="col-2">
            <button  class="btn-outline-primary btn-sm btn" :disabled="!item.column" :title="$t('Add criteria to the filter')" @click="addToFilter">{{$t('Add To Filter')}}</button>
           <!-- <button v-if="index == filterItems.length-1 && item.column" class="btn-link btn py-0" :title="$t('Add')" @click="addItem"><i class="bi bi-plus-circle"></i></button>
            <button v-if="index < filterItems.length-1 && item.column" class="btn-link btn py-0 text-danger" :title="$t('Remove')" @click="removeItem($event,item)"><i class="bi bi-dash-circle"></i></button>-->
        </div>


    </div>
</template>